<template>
  <div class="row">
    <div class="col-md-12">
      <simple-wizard
        @onCompleted="completeWizard"
        :finishButtonText="$t('common.finish')"
        :nextButtonText="$t('common.next')"
        :prevButtonText="$t('common.prev')"
      >
        <template slot="header">
          <h3 class="card-title">{{ $t('didactics.student_wizard.title') }}</h3>
        </template>

        <wizard-tab :before-change="() => validateStep(1)">
          <template slot="label">
            <octo-icon icon="list"></octo-icon>
            <p>{{ $t('didactics.student_wizard.registry_choice') }}</p>
          </template>
          <student-wizard-first-step ref="studentWizardFirstStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(2)">
          <template slot="label">
            <octo-icon icon="academic"/>
            <p>{{ $t('didactics.student_wizard.course_choice') }}</p>
          </template>
          <student-wizard-second-step ref="studentWizardSecondStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(3)">
          <template slot="label">
            <octo-icon icon="statistics"/>
            <p>{{ $t('didactics.student_wizard.report') }}</p>
          </template>
          <student-wizard-third-step :registry="registry" :course="course" :key="reportKey"/>
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>

<script>
import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Registry from "@/models/registry";
import Course from "@/models/course";
import StudentWizardFirstStep from "@/pages/Students/student-wizard/StudentWizardFirstStep";
import StudentWizardSecondStep from "@/pages/Students/student-wizard/StudentWizardSecondStep";
import StudentWizardThirdStep from "@/pages/Students/student-wizard/StudentWizardThirdStep";
import {endpoints} from "@/routes/endpoints";
import {MessageBox} from "element-ui";

export default {
  name: "WizardPage",
  components: {
    StudentWizardThirdStep,
    StudentWizardSecondStep,
    StudentWizardFirstStep,
    OctoIcon,
    WizardTab,
    SimpleWizard,
  },
  data() {
    return {
      registry: this.$_.cloneDeep(Registry),
      course: this.$_.cloneDeep(Course),
      reportKey: 0
    }
  },
  methods: {
    async validateStep(step) {
      let check = false;
      switch (step) {
        case 1:
          const registry = this.$refs.studentWizardFirstStep.getSelectedRegistry();
          if (registry) {
            this.registry = registry;
            check = true;
            this.reportKey++
          }
          break;
        case 2:
          const course = await this.$refs.studentWizardSecondStep.getCourse();
          if (course) {
            this.course = course;
            check = true;
            this.reportKey++
          }
          break;
        case 3:
          check = true;
          break;
        default:
          check = false;
          break;
      }

      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },
    completeWizard() {
      this.$api.post(
        endpoints.STUDENT_GENERATE
          .replace('{registryId}', this.registry.id)
          .replace('{courseId}', this.course.id)
      )
        .then(() => {
          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('didactics.student_wizard.back_on_students_page'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          })
            .finally(() => {
              this.$router.push({name: 'students.waiting.index'});
            });
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
    }
  }
}
</script>

<style scoped>

</style>
