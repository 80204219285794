var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"registrySearch"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center align-items-end"},[_c('div',{staticClass:"col-12 col-md-5"},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"flex-grow-1",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","label":_vm.$t('fields.firstname'),"error":errors[0]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-5"},[_c('ValidationProvider',{attrs:{"name":"lastname","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"flex-grow-1",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","label":_vm.$t('fields.lastname'),"error":errors[0]},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-2"},[_c('base-button',{staticClass:"ml-3 mb-2",attrs:{"icon":"","link":""},on:{"click":_vm.validate}},[_c('octo-icon',{attrs:{"icon":"magnifying-glass"}})],1)],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-md-4"},[(_vm.registryKey > 0)?_c('h4',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('common.results_number') + ': ' + _vm.registries.length)+" ")]):_vm._e(),_c('ul',{staticClass:"list-group list-group-flush max-height-list"},_vm._l((_vm.registries),function(registry,index){return _c('list-group-item-component',{key:("registry-" + index),attrs:{"label":((_vm.$options.filters.optional(registry, 'name')) + " " + (_vm.$options.filters.optional(registry, 'surname')))}},[_c('template',{slot:"value"},[_c('base-button',{staticClass:"ml-auto text-capitalize mb-0",attrs:{"icon":"","link":"","selected":_vm.selectedRegistry && _vm.selectedRegistry.id === registry.id},on:{"click":function($event){return _vm.selectRegistry(registry)}}},[_c('octo-icon',{attrs:{"icon":"right-arrow"}})],1)],1)],2)}),1)]),_c('div',{staticClass:"col-12 col-md-8"},[(_vm.selectedRegistry)?_c('registry-personal-data',{key:_vm.registryKey,attrs:{"editable":false,"registry":_vm.selectedRegistry}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }