<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('didactics.student_wizard.second_step_title') }}</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <ValidationObserver ref="secondStep">
          <ValidationProvider name="course" rules="required" v-slot="{ passed, failed, errors }">
            <base-input :label="$t('fields.course')"
                        :error="errors[0]"
                        :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]">
              <el-select
                class="select-default text-uppercase"
                filterable
                :placeholder="$t('fields.type_to_search')"
                value-key="id"
                v-model="selectedCourse"
              >
                <el-option
                  class="select-default text-uppercase"
                  v-for="(course) in courses"
                  :value="course"
                  :label="course.title"
                  :key="`course-${course.id}`"
                >
                </el-option>
              </el-select>
            </base-input>
          </ValidationProvider>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Select, Option} from "element-ui";
import Course from "@/models/course";

export default {
  name: "StudentWizardSecondStep",
  components: {[Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      selectedCourse: this.$_.cloneDeep(Course)
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses'
    }),
  },
  methods: {
    getCourse() {
      return new Promise((resolve, reject) => {
        this.$refs.secondStep.validate()
          .then((success) => {
            if (success) {
              resolve(this.selectedCourse);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }
}
</script>

<style scoped>

</style>
