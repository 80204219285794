<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('didactics.student_wizard.third_step_title') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <registry-personal-data :editable="false" :registry="registry" :key="registryKey"/>
      </div>
      <div class="col-12 col-md-4">
        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.course') }}</h4>
          </div>
          {{ course.title }}
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import Course from "@/models/course";
import Registry from "@/models/registry";
import RegistryPersonalData from "../../Registries/components/RegistryPersonalData";

export default {
  name: "StudentWizardThirdStep",
  components: {RegistryPersonalData},
  data() {
    return {
      registryKey: 0
    }
  },
  mounted() {
    this.registryKey++;
  },
  props: {
    course: {
      type: Object,
      default: Course
    },
    registry: {
      type: Object,
      default: Registry
    },
  }
}
</script>

<style scoped>

</style>
