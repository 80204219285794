<template>
  <ValidationObserver ref="registrySearch">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-end">
        <div class="col-12 col-md-5">
          <ValidationProvider
            name="firstname"
            rules="min:3"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              class="flex-grow-1"
              v-model="name"
              type="text"
              :label="$t('fields.firstname')"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
            </base-input>
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-5">
          <ValidationProvider
            name="lastname"
            rules="min:3"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              class="flex-grow-1"
              v-model="surname"
              type="text"
              :label="$t('fields.lastname')"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
            </base-input>
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-2">
          <base-button
            @click="validate"
            icon
            link
            class="ml-3 mb-2">
            <octo-icon icon="magnifying-glass"></octo-icon>
          </base-button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-4">
          <h4 v-if="registryKey > 0" class="text-capitalize">
            {{ $t('common.results_number') + ': ' + registries.length }}
          </h4>
          <ul class="list-group list-group-flush max-height-list">
            <list-group-item-component
              v-for="(registry, index) in registries"
              v-bind:key="`registry-${index}`"
              :label="`${$options.filters.optional(registry, 'name')} ${$options.filters.optional(registry, 'surname')}`"
            >
              <template slot="value">
                <base-button
                  icon
                  link
                  class="ml-auto text-capitalize mb-0"
                  :selected="selectedRegistry && selectedRegistry.id === registry.id"
                  @click="selectRegistry(registry)"
                >
                  <octo-icon icon="right-arrow"/>
                </base-button>
              </template>
            </list-group-item-component>
          </ul>
        </div>
        <div class="col-12 col-md-8">
          <registry-personal-data v-if="selectedRegistry"
                                  :editable="false"
                                  :registry="selectedRegistry"
                                  :key="registryKey"/>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import OctoIcon from "../octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints"
import BaseAlert from "../BaseAlert";
import RegistryPersonalData from "@/pages/Registries/components/RegistryPersonalData";
import ListGroupItemComponent from "../ListGroupItemComponent";

export default {
  name: "OctoRegistrySelector",
  components: {ListGroupItemComponent, RegistryPersonalData, BaseAlert, OctoIcon},
  data() {
    return {
      name: '',
      surname: '',
      endpoints: endpoints,
      registries: [],
      selectedRegistry: null,
      registryKey: 0
    }
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.registrySearch.validate()
          .then((success) => {
            if (success) {
              this.$fullLoading.show();
              this.$api.post(endpoints.REGISTRY_SEARCH_REGISTRIES_BY_NAME, {name: this.name, surname: this.surname})
                .then((resp) => {
                  this.$fullLoading.hide();
                  this.registries = resp.data;
                  this.registryKey++;
                  this.selectedRegistry = null;
                })
                .catch(() => {
                  this.$fullLoading.hide();
                  this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
                })
              resolve();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    selectRegistry(registry) {
      this.selectedRegistry = this.$_.cloneDeep(registry);
      this.registryKey++;
    },

    getSelectedRegistry: function () {
      if (this.$_.isEmpty(this.selectedRegistry)) {
        return null;
      }

      return this.$_.cloneDeep(this.selectedRegistry);
    }
  }
}
</script>

<style scoped>
.max-height-list {
  max-height: 400px;
  overflow: auto;
}
</style>
